import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { StorageUtils } from '../shared/storage-utils';


@Injectable({ providedIn: 'root' })
export class AuthGuardService  {

    constructor(
        private router: Router,
    ) { }


    /**
     * Determines if the user can activate the route.
     *
     * @param {ActivatedRouteSnapshot} route - The route to be activated.
     * @param {RouterStateSnapshot} state - The current router state.
     * @return {boolean} Returns true if the user can activate the route, otherwise false.
    */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean{
        const token = StorageUtils.getAuthToken();
        if (token) {
            if (this.checkRoles(token) && !(route.data && route.data.page)) {
                return true;
            } else {
                if ((!route.data && route.data.page)) {
                    this.router.navigate(['/login']);
                } else {
                    return true;
                }
            }
        } else {
            if (!(route.data && route.data.page)) {
                this.router.navigate(['/login']);
            } else {
                return true;
            }
        }
    }


    /**
     * Checks if the given token has the 'ROLE_EMPLOYEE' authority.
     *
     * @param {string} token - The token to be decoded and checked.
     * @return {boolean} Returns true if the token has the 'ROLE_EMPLOYEE' authority, otherwise false.
    */
    checkRoles(token){
        const tokenDecode = jwtDecode(token);                
        if (tokenDecode['authorities'] && tokenDecode['authorities'].find(element => element === 'ROLE_EMPLOYEE')) {
            return true;
        }else{
            return false;
        }
    }

}
